import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import styles from './SupportPage.module.css'; 
import './EmailVerification.css'; // Adjust the path as needed

const EmailVerification = () => {
  const [status, setStatus] = useState('Verifying...');
  const location = useLocation();

  useEffect(() => {
    // Check for query parameters in the URL
    const queryParams = new URLSearchParams(location.search);
    const verificationStatus = queryParams.get('status');
    const error = queryParams.get('error');

    if (verificationStatus === 'verified') {
      setStatus(<>Your email has been successfully verified! You can now log into your account.<br />
        <br />
        You may now close this window and return to the application.
      </>);
    } else if (verificationStatus === 'failed') {
      setStatus(`Verification failed: ${error || 'Please try again.'}`);
    } else {
      setStatus('Verifying...');
    }
  }, [location]);

  const [isMenuOpen, setIsMenuOpen] = useState(false); // State for controlling menu visibility
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth); 

  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top when the component is mounted
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(prevState => !prevState); // Toggles menu visibility
  };

  useEffect(() => {
    const handleResize = () => {
      setScreenHeight(window.innerHeight);
      setScreenWidth(window.innerWidth); 
    };

    // Add event listener for resizing the window
    window.addEventListener('resize', handleResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Calculate the margin based on screen height
  const margin = screenHeight * 0.12; // Example: 10% of the screen height
  const menuRight = screenWidth * .01;

  return (
    <div className='container'>
      <header className={styles.header}>
        <img src={require('../assets/logoWhite.png')} alt="Logo" />
        {/* Menu Button */}
        <button className={styles.menuButton} style={{ right: menuRight }} onClick={toggleMenu}>☰</button>
      </header>

      {/* Side Menu */}
      <div className={`${styles.sideMenu} ${isMenuOpen ? styles.open : ''}`}>
        {/* Close Button */}
        <button className={styles.closeButton} style={{ right: menuRight }} onClick={toggleMenu}>×</button>

        <nav>
          <ul>
            <li><Link to="/support">Support</Link></li>
            <li><Link to="/privacy-policy">Privacy Policy</Link></li>
            <li><Link to="/terms-and-conditions">Terms & Conditions</Link></li>
          </ul>
        </nav>  

        <img src={require('../assets/stackedLogo.png')} alt="Stack" />
      </div>

      <div className="status-message">
        <p>{status}</p>
      </div>
      
      <div className="signature">
        <p> © 2024 Andrew Smith</p>
      </div>
    </div>
  );
};

export default EmailVerification;