import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { Link } from 'react-router-dom';
import styles from './SupportPage.module.css'; // Import styles as a module

const SupportPage = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false); // State for controlling menu visibility
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth); 

  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top when the component is mounted
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(prevState => !prevState); // Toggles menu visibility
  };

  useEffect(() => {
    const handleResize = () => {
      setScreenHeight(window.innerHeight);
      setScreenWidth(window.innerWidth); 
    };

    // Add event listener for resizing the window
    window.addEventListener('resize', handleResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
  const menuRight = screenWidth * .01;
 
  return (
    <div className={styles.supportPage}>
      <header className={styles.header}>
        <img src={require('../assets/logoWhite.png')} alt="Logo" />
        {/* Menu Button */}
        <button className={styles.menuButton} style={{ right: menuRight }} onClick={toggleMenu}>☰</button>
      </header>

      {/* Side Menu */}
      <div className={`${styles.sideMenu} ${isMenuOpen ? styles.open : ''}`}>
        {/* Close Button */}
        <button className={styles.closeButton} style={{ right: menuRight }} onClick={toggleMenu}>×</button>

        <nav>
          <ul>
            <li><Link to="/support">Support</Link></li>
            <li><Link to="/privacy-policy">Privacy Policy</Link></li>
            <li><Link to="/terms-and-conditions">Terms & Conditions</Link></li>
          </ul>
        </nav>  

        <img src={require('../assets/stackedLogo.png')} alt="Stack" />
      </div>

      <section className={styles.section}>
        <h1>Welcome to a healthier you.</h1>
        <ul>
          <li>Macros&More is an all-in-one nutrition tracking app created with the sole purpose of improving
              your health. Designed to make tracking your nutrition easy, accurate, and enjoyable, Macros&More
              offers a wide variety of features to help you effortlessly manage your nutritional needs. This app
              automatically calculates your personalized nutritional goals using information like height, weight,
              age, pregnancy status and gender. You can also adjust these goals to suit your specific needs (we recommend
              consulting a physician to determine your ideal daily intake).</li>

          <li>Create and save custom foods and recipes, which can be added to your daily food log at any time.
              And it's not just about food—Macros&More also lets you track your daily water intake, because
              proper hydration is just as important as proper nutrition. Meet your goals regularly to unlock
              achievements, which you can view in the 'My Info' tab in the settings menu.</li>

          <li>We care about your privacy, so the only personally identifiable information we store is your name 
              and email address, just in case you ever need to recover your account. Rest assured, your data is
              securely managed 
              and stored on a trusted, industry-standard platform, ensuring privacy and reliability.</li>
        </ul>
      </section>

      <section className={styles.section2}>
        <h2>Frequently Asked Questions (FAQs)</h2>
        <ul>
          <h3>How do I create an account or sign up?</h3>
          <li>You can sign up for a new account by pressing 'Create Account' on the login screen, then proceed by
             entering all of the required information. A verification link will then be sent to your email,
            after verifying your email, you will then be able to log in to your account.
          </li>
          <h3>How do I reset my password?</h3>
          <li>You can reset your password in the app by either pressing 'Forgot Password?' in the login screen
            or by pressing 'Edit Info' in the settings menu. A secure code will be emailed to you that will allow you
            to reset your password.
          </li>
          <h3>How do I calculate my nutritional goals?</h3>
          <li>After creating a new account and logging in for the first time, you will be brought to the goal calculator screen.
            None of the information on this screen is saved or stored, it is only used to calculate your nutritional goals. 
            You can update your information at any time by pressing 'Calculate Daily Goals' in the settings menu. 
          </li>
          <h3>Can I set my nutritional goals manually?</h3>
          <li>Yes. Although the app will automatically set your nutritional goals based on the information you provide, you can
            also manually adjust any or all goals by pressing 'Manually Adjust Daily Goals' in the settings menu. You can 
            revert back to the automatically generated goals at any time by visiting 'Calculate Daily Goals' in settings.
          </li>
          <h3>Can I add my own food items?</h3>
          <li>Yes. Although Macros&More uses the USDA FoodData Central Database for generic searches, we recommend adding your own 
            custom food items to help personalize the app to better suit your needs. You can add a new food item by pressing the 
            'Create New Food Item' option in the Food Menu and then proceed with entering all of the relevant information.
            Custom foods are saved in the 'My Food' section of the Food Menu.
          </li>
          <h3>Can I combine multiple foods to create new recipes?</h3>
          <li>Yes, you can create a new recipe by selecting the 'Create New Recipe' option in the 'My Recipes' section of the 
            Food Menu. Recipes allow you to combine multiple food items, create serving sizes, and even provide preparation
            directions. Recipes are saved in the 'My Recipes' section of the Food Menu and can be added to your daily food 
            log at any time.
          </li>
          <h3>How is my information stored and used?</h3>
          <li>We only collect and store information that is necessary for the proper functioning of our app. The only personally
            identifiable information we store is your name and email address, just in case you ever need to recover your account.
            You can find out more by viewing our <a href="https://andrewsmithdevelopment.com/privacy-policy">Privacy Policy</a>.
          </li>
        </ul>
      </section>
      
      <section className={styles.section3}>
        <h2>Contact Support</h2>
        <ul>
          <h3>We would love to hear from you!</h3>
          <li>If you have any other questions, comments, or concerns, feel free to reach out to us at 
            <a href="mailto:support@andrewsmithdevelopment.com"> support@andrewsmithdevelopment.com</a></li>
        </ul>

        <button
          className={styles.contactButton}
          onClick={() => window.location.href = "mailto:support@andrewsmithdevelopment.com?subject=Support Request&body=Please describe your issue here."}
        >
          Contact Us
        </button>
      </section>
      
      <footer>
        <div className="signature footer-signature"> 
          <p>© 2024 Andrew Smith</p>
        </div>
      </footer>
    </div>
  );
};

export default SupportPage;
