import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './SupportPage.module.css'; 
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top when the component is mounted
  }, []);

  const [isMenuOpen, setIsMenuOpen] = useState(false); // State for controlling menu visibility
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth); 

  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top when the component is mounted
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(prevState => !prevState); // Toggles menu visibility
  };

  useEffect(() => {
    const handleResize = () => {
      setScreenHeight(window.innerHeight);
      setScreenWidth(window.innerWidth); 
    };

    // Add event listener for resizing the window
    window.addEventListener('resize', handleResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Calculate the margin based on screen height
  const margin = screenHeight * 0.12; // Example: 10% of the screen height
  const menuRight = screenWidth * .01;

  return (
    <div className="privacy-policy">
      <header className={styles.header}>
        <img src={require('../assets/logoWhite.png')} alt="Logo" />
        {/* Menu Button */}
        <button className={styles.menuButton} style={{ right: menuRight }} onClick={toggleMenu}>☰</button>
      </header>

      {/* Side Menu */}
      <div className={`${styles.sideMenu} ${isMenuOpen ? styles.open : ''}`}>
        {/* Close Button */}
        <button className={styles.closeButton} style={{ right: menuRight }} onClick={toggleMenu}>×</button>

        <nav>
          <ul>
            <li><Link to="/support">Support</Link></li>
            <li><Link to="/privacy-policy">Privacy Policy</Link></li>
            <li><Link to="/terms-and-conditions">Terms & Conditions</Link></li>
          </ul>
        </nav>  

        <img src={require('../assets/stackedLogo.png')} alt="Stack" />
      </div>

      <div className="terms-container">
        <h1>Privacy Policy for Macros&More</h1>
        <p><strong>Last Updated:</strong> 11/07/2024</p>

        <p>
          Macros&More (“we,” “our,” or “us”) values your privacy. This Privacy Policy explains what information we collect, 
          how we use and store it, and your rights related to your personal information when you use our app.
        </p>


        <h2>1. Introduction</h2>
        <p className="details">
          By using Macros&More, you agree to the collection and use of your information in accordance with this Privacy Policy. 
          If you do not agree to this policy, please do not use our app.
        </p>

        <h2>2. Information We Collect and Store</h2>
        <ul className='collect'>
          <li><strong>Account Information:</strong> When you register, we collect your first name, last name, email address, and password. This information is stored to create your account and facilitate secure access.</li>
          <li><strong>Temporary Personal Information:</strong> To calculate personalized nutritional goals, we request your height, weight, age, gender, pregnancy status, activity level and desired weight loss goal. This data is used solely for calculations and is not stored in our system.</li>
          <li><strong>Stored User Data:</strong>
            <ul>
              <li><strong>Nutritional Goals:</strong> Calculated from the information provided, these goals are stored to personalize your experience and track progress.</li>
              <li><strong>Food Logs and Totals:</strong> We store daily food logs, total nutritional intake, and progress summaries to help you monitor your diet and reach your goals.</li>
              <li><strong>Custom Foods and Recipes:</strong> If you add custom foods or recipes, these entries are saved for future use.</li>
            </ul>
          </li>
        </ul>

        <h2>3. How We Use Your Information</h2>
        <p className="details">
          We use your information to:
          <ul className='list'>
            <li>Calculate personalized nutritional goals.</li>
            <li>Track your nutritional intake and display progress.</li>
            <li>Enable access to your custom foods and recipes.</li>
            <li>Respond to inquiries and provide customer support.</li>
          </ul>
        </p>

        <h2>4. Legal Bases for Processing Your Information (for EU Users)</h2>
        <p className="details">
          For users in the European Union, we process your information based on the following legal grounds:
          <ul className='list'>
            <li><strong>Consent:</strong> By creating an account, you consent to our processing of your information as described in this policy.</li>
            <li><strong>Contractual Obligation:</strong> Processing is necessary to provide our services to you.</li>
            <li><strong>Legitimate Interests:</strong> For purposes such as improving our app, responding to user inquiries, and ensuring data security.</li>
          </ul>
        </p>

        <h2>5. How We Share Your Information</h2>
        <p className="details">
          We do not sell your personal information. We may share your information only in the following cases:
          <ul className='list'>
            <li><strong>Service Providers:</strong> We work with trusted service providers to help operate our app (e.g., data storage, customer support). These providers are bound by confidentiality obligations.</li>
            <li><strong>Legal Requirements:</strong> We may disclose your information if required by law (e.g., to comply with a subpoena or government request).</li>
            <li><strong>Aggregated Data:</strong> We may share anonymized data for analytics or research purposes.</li>
          </ul>
        </p>

        <h2>6. Data Security</h2>
        <p className="details">
          We implement security measures to protect your personal information from unauthorized access, use, or disclosure. However, no method of transmission over the internet is 100% secure, and we cannot guarantee absolute security.
        </p>

        <h2>7. Your Privacy Rights</h2>
        <h3>Rights Under CCPA (California Residents)</h3>
        <p className="details">
          If you are a California resident, you have specific rights:
          <ul className='list'>
            <li><strong>Right to Access:</strong> You may request access to the personal information we have collected about you over the past 12 months.</li>
            <li><strong>Right to Deletion:</strong> You may request deletion of your personal information, subject to certain exceptions.</li>
            <li><strong>Right to Non-Discrimination:</strong> Exercising your privacy rights will not result in discrimination.</li>
          </ul>
        </p>

        <h3 className="rights">Rights Under GDPR (EU Residents)</h3>
        <p className="details">
          If you are an EU resident, you have the following rights:
          <ul className='list'>
            <li><strong>Right to Access:</strong> You can request a copy of the personal information we hold about you.</li>
            <li><strong>Right to Rectification:</strong> You may request correction of inaccurate information.</li>
            <li><strong>Right to Erasure:</strong> You may request deletion of your personal information under certain circumstances.</li>
            <li><strong>Right to Restrict Processing:</strong> You can request a limitation on the processing of your data.</li>
            <li><strong>Right to Data Portability:</strong> You may request a copy of your data in a machine-readable format.</li>
            <li><strong>Right to Object:</strong> You have the right to object to certain types of data processing.</li>
          </ul>
        </p>

        <h2>8. Data Retention</h2>
        <p className="details">
          We retain your personal information as long as necessary to provide our services and fulfill the purposes outlined in this policy. We may also retain information as required by law or for legitimate business purposes.
        </p>

        <h2>9. Children's Privacy</h2>
        <p className="details">
          Macros&More is not intended for use by children under 13. We do not knowingly collect information from children under this age. If we discover that a child under 13 has provided us with personal information, we will promptly delete it.
        </p>

        <h2>10. Changes to This Privacy Policy</h2>
        <p className="details">
          We may update this Privacy Policy to reflect changes to our practices or for operational, legal, or regulatory reasons. We will notify you of significant changes by posting a notice within the app or by other means, such as email. Your continued use of the app signifies acceptance of the revised policy.
        </p>

        <h2>11. Contact Us</h2>
        <p className="details">
          If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:<br /><br />
          <strong>Email:</strong> support@andrewsmithdevelopment.com
        </p>
      </div>

      <footer>
        <div className="signature footer-signature"> 
          <p>© 2024 Andrew Smith</p>
        </div>
    </footer> 
    </div>
  );
};

export default PrivacyPolicy;
  